import React from 'react';

const Skeleton: React.FC<{ size: 'xl' | 'large' | 'lg' | 'medium' | 'md' | 'small' | 'sm' | 'xs' }> = ({ size }) => {
  const getSkeleton = (size: string) => {
    switch (size) {
      case 'xl':
        return (
          <div className="w-full flex flex-col items-start space-y-2">
            <div className="skeleton-md" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-md" />
            <div className="skeleton-sm mb-4" />
            <div className="skeleton-md" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-md" />
            <div className="skeleton-sm" />
          </div>
        );
      case 'large':
      case 'lg':
        return (
          <div className="w-full flex flex-col items-start space-y-2">
            <div className="skeleton-md" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-md" />
            <div className="skeleton-sm" />
          </div>
        );
      case 'medium':
      case 'md':
        return (
          <div className="w-full flex flex-col items-start space-y-2">
            <div className="skeleton-md" />
            <div className="skeleton-lg" />
            <div className="skeleton-lg" />
            <div className="skeleton-md" />
            <div className="skeleton-md" />
            <div className="skeleton-sm" />
          </div>
        );
      case 'small':
      case 'sm':
        return (
          <div className="w-full flex flex-col items-start space-y-2">
            <div className="skeleton-md" />
            <div className="skeleton-md" />
            <div className="skeleton-sm" />
          </div>
        );
      case 'xs':
        return <div className="skeleton-sm" />;
      default:
        return null;
    }
  };

  return getSkeleton(size);
};

export default Skeleton;
