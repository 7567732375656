import React, { useEffect, useState } from 'react';
import {
  default as usePlacesService,
} from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { SearchProps } from 'antd/lib/input';
import { AutocompletePrediction } from 'react-places-autocomplete';
import Skeleton from '../Skeleton';

const apiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;

type GooglePlacesAutocompleteProps = {
  defaultAddress?: string;
  onSelect: (
    place: AutocompletePrediction | undefined
  ) => void;
  onBlur?: () => void;
  placeholder?: string;
  inputVariant?: string;
  isError?: boolean;
  // Omit these props because of weird type error
  inputProps?: Omit<SearchProps, 'sx' | 'css' | 'size' | 'prefix' | 'value'>;
  ['data-testid']?: string;
  defaultValue?: string;
  resultsOnly?: boolean;
}

const GooglePlacesAutocomplete: React.FC<GooglePlacesAutocompleteProps> = ({
  defaultAddress,
  onSelect,
  onBlur,
  inputProps,
  placeholder,
  inputVariant = 'input',
  isError,
  defaultValue,
  resultsOnly = false,
  ...props
}) => {
  const [place, setPlace] = useState<
    AutocompletePrediction | null
  >(null);
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce: 800,
  });
  const testId = props['data-testid'];
  const [isInUse, setIsInUse] = useState(resultsOnly);

  useEffect(() => {
    if (defaultValue && isInUse) {
      getPlacePredictions({
        input: defaultValue,
        types: ['premise', 'street_address', 'subpremise']
      });
    }
  }, [defaultValue, isInUse]);

  return (
    <div
      className="relative w-full"
    >
      <input
        data-testid={testId}
        className={resultsOnly ? 'hidden' : `text-black input-lg ${isError ? 'border-red-600' : ''}`}
        variant={inputVariant}
        defaultValue={defaultAddress}
        value={place ? place.description : defaultValue}
        onChange={(evt) => {
          getPlacePredictions({
            input: evt.target.value,
            types: ['premise', 'street_address', 'subpremise']
          });
        }}
        placeholder={placeholder}
        onKeyDown={() => {
          setIsInUse(true);
          if (place) {
            setPlace(null);
          }
        }}
        onBlur={() => {
          if (typeof onBlur !== 'undefined') {
            onBlur();
          } /*else if (!place && placePredictions.length) {
            setPlace(placePredictions[0]);
            onSelect(placePredictions[0]);
          }*/
        }}
        {...inputProps}
      />
      <div
        className="flex flex-col gap-1 pt-1"
      >
        {isInUse && !place && !isPlacePredictionsLoading && placePredictions.map((item, ix) => (
          <span
            key={item.place_id}
            className="cursor-pointer bg-paleOrange hover:bg-lightOrange py-4 px-6 font-bold"
            data-testid={`${testId}_result_${ix}`}
            onClick={e => {
              e.stopPropagation();
              setPlace(item);
              onSelect(item);
            }}
          >
            {item.description}
          </span>
        ))}
      </div>
      {isPlacePredictionsLoading && (
        <Skeleton size="md" />
      )}
    </div>
  );
};

export default GooglePlacesAutocomplete;
