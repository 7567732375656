import LogOut from 'Common/components/Navigation/NavContent/Logout';
import { AppRoutePath } from 'Pages/routes';
import PersonOutline from 'Images/svg/person-outline.svg';
import FormIcon from 'Images/svg/form.svg';

export type NavItem = {
  href: string | null;
  getHref?: () => Promise<string>;
  content: React.ReactNode;
}

export const customerNavItems: NavItem[] = [
  {
    href: AppRoutePath.HOME,
    content: 'Bookings',
  },
  {
    href: AppRoutePath.CUSTOMER_ACCOUNT_LOCATIONS,
    content: 'My Locations',
  },
  {
    href: AppRoutePath.CUSTOMER_ACCOUNT_PAYMENT,
    content: 'Payment Options',
  },
  {
    href: AppRoutePath.CUSTOMER_ACCOUNT_ORDER_HISTORY,
    content: 'Order History',
  },
  {
    href: AppRoutePath.CUSTOMER_ACCOUNT_SETTINGS,
    content: 'Settings',
  },
  {
    href: AppRoutePath.CUSTOMER_ACCOUNT_SUPPORT,
    content: 'Support',
  },
  {
    href: null,
    content: <LogOut />,
  },
];

export const publicNavItems: NavItem[] = [
  {
    href: AppRoutePath.LOGIN,
    content: (
      <span className="sm:stroke-white md:stroke-primary sm:fill-white md:fill-primary sm:text-white md:text-primary flex gap-2 sm:text-sm md:text-base">
        <PersonOutline />
        Log In
      </span>
    ),
  },
  {
    href: AppRoutePath.CUSTOMER_SIGNUP,
    content: (
      <span className="sm:stroke-white md:stroke-black sm:fill-white md:fill-black sm:text-white md:text-black flex gap-2 sm:text-sm md:text-base">
        <FormIcon />
        Sign Up
      </span>
    ),
  },
];

export const serviceProviderNavItems: NavItem[] = [
  {
    href: AppRoutePath.SERVICE_PROVIDER_DASHBOARD,
    content: 'Calendar',
  },
  {
    href: AppRoutePath.SERVICE_PROVIDER_DASHBOARD_CUSTOMERS,
    content: 'Customers',
  },
  {
    href: AppRoutePath.SERVICE_PROVIDER_DASHBOARD_ORDER_HISTORY,
    content: 'Order History',
  },
  {
    href: AppRoutePath.SERVICE_PROVIDER_DASHBOARD_SERVICES,
    content: 'Services',
  },
  {
    href: AppRoutePath.SERVICE_PROVIDER_DASHBOARD_SETTINGS,
    content: 'Settings',
  },
  {
    href: AppRoutePath.SERVICE_PROVIDER_DASHBOARD_SCHEDULE,
    content: 'Schedule',
  },
  /*
  {
    href: AppRoutePath.SERVICE_PROVIDER_DASHBOARD_SETTINGS_PLAN,
    content: 'Plan',
  },
  */
  {
    href: null,
    content: <LogOut />,
  },
];

export const businessNavItems: NavItem[] = [
  /*
  {
    href: AppRoutePath.BUSINESS_ACTIVITY_DASHBOARD,
    content: 'Dashboard',
  },
  */
  {
    href: AppRoutePath.BUSINESS_SCHEDULE,
    content: 'Schedule',
  },
  {
    href: AppRoutePath.BUSINESS_LOCATIONS,
    content: 'Locations',
  },
  {
    href: AppRoutePath.BUSINESS_ACCOUNT_PROFILE,
    content: 'Profile',
  },
  {
    href: AppRoutePath.BUSINESS_ACCOUNT_BILLING,
    content: 'Billing',
  },
  {
    href: AppRoutePath.BUSINESS_MEMBERS,
    content: 'Members',
  },
  {
    href: null,
    content: <LogOut />,
  },
];
